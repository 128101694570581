<template>
  <div id="demo">
    <div class="div div1"></div>
    <div class="div div2"></div>
    <button @click="buttonClick">点击</button>
  </div>
</template>

<script>

export default {
  name: 'errpr',
  mounted(){
    window._hmt.push(["_trackEvent", "demo", "into", "demo页面"]);
  },
  methods:{
    buttonClick(){
      window._hmt.push(["_trackEvent", "button", "click", "demo页面点击事件"]);
    }
  }
}
</script>

<style scoped lang="scss">
.div {
  width: 400px;
  height: 50px;
  border: 1px solid red;
}
.div1 {
  // cover: 适应最大的边，图片与最大的边一样，另一边自适应，不能完全显示在框内，超过部分被隐藏
  background-image: url("../assets/headers.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  margin-top: 30px;
}
.div2 {
  // contain ： 适应最小的边，图片与最窄的一样，另一边自适应，能完全显示在框内，框会有空白
  background-image: url("../assets/headers.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  margin-top: 30px;
}
</style>
